/* Login */
.userLogin {
  min-height: 100vh;
  min-width: 100vw;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  background: -webkit-linear-gradient(to right, #928DAB, #00d2ff);
  background: linear-gradient(to right, #928DAB, #00d2ff);
  /* padding: 100px 180px 100px 180px; */
  z-index: 1;
  /* background-color: dodgerblue; */

  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  align-items: center;
  padding: 10%;
}

:where(.css-dev-only-do-not-override-w8mnev).ant-layout .ant-layout-sider-children {
  height: 100vh;
}

@media only screen and (max-width: 900px) {
  .userLogin {
    background-color: dodgerblue;
    display: inline-flex;
    flex-direction: column-reverse;
    justify-content: space-around;
    /* display: row; */
  }

  .userLogin>.loginLeft {
    display: none;
  }

  .userLogin>.loginRight {
    width: 80%;
  }

}

.dashboard {
  padding: 0px !important;
  background-color: #EBE8F7;
}

.request-card {
  box-shadow: 13px 10px 10px -3px rgba(0, 0, 0, 0.1);
}

:where(.css-dev-only-do-not-override-w8mnev).ant-card-bordered {
  border: 0px solid #f0f0f0;
}


.ant-btn-primary {
  background-color: rgb(63, 63, 194);

}

@media only screen and (max-width: 996px) {
  .ant-col-sm-12:first-child {
    display: none;
  }

  .ant-row {
    justify-content: center;
  }
}

.loginLeft {
  background-color: rgb(255, 255, 255);

  /* border: 2px solid rgb(0, 0, 0); */
  /* padding: 30px 30px; */
  height: 70vh;
  /* display: table-cell; */
  vertical-align: top;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding-left: 3%;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 15px 15px;
  flex: 1;
  /* border-right: 1px solid transparent; */
  margin-right: 3%;
  border-radius: 6px;
  width: 35%;

}

.loginRight {
  background-color: rgb(255, 255, 255);
  /* border: 2px solid rgb(0, 0, 0); */
  /* box-shadow: 70px; */
  /* font-size: 20px; */
  border-radius: 6px;
  width: 60%;
  box-shadow: rgba(0, 0, 0, 0.35) 10px 15px 15px;

}

.demo-button {
  padding-left: 20px;
}


.login-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* padding-inline: 27px; */
}

.login-form {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 500px;
}

.login-form>input {
  border: 1px solid rgba(209, 213, 219);
  height: 40px;
  text-indent: 10px;
  font-family: "Inter", sans-serif;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  font-weight: 400;
}

.country-code {
  height: 100%;
  border-left: solid .2px black;

}

.login-form>input:focus,
.login-form>div>input:focus {
  outline: none;
}

.login-form>input:first-of-type {
  border-radius: 6px 6px 0px 0px;
}

.login-form>input:last-of-type {
  border-radius: 0px 0px 6px 6px;
  border-top: none;
}

.login-form>button {
  background-color: #FF8C38;
  border: none;
  border-radius: 6px;
  height: 55px;
  margin-top: 22px;
  color: white;
  font-family: "Inter", sans-serif;
}

.login-form>button:disabled {
  background-color: #aaaaaa;
  cursor: not-allowed;
}

.login-text {
  font-family: "Inter", sans-serif;
  color: rgb(0, 0, 0);
}

.login-extra {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.remember {
  display: flex;
  flex-direction: row;
}

.remember>p {
  padding-left: 8px;
}

.demo-button {
  background-color: #00d2ff;
  padding-right: 20px;
  height: 2em;
  margin-left: 20px;
  border-radius: 20px;
  color: rgb(255, 50, 50);
}

/* .login-extra>div {
    padding-left: 20px;
    padding-top: 20px;
} */

.reset-password {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}


.reset {
  display: block;
  width: 30em;
  min-height: 20em;
  padding: 7em 2em;
  background-color: rgb(255, 255, 255);
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.form-box {
  padding: 0px 2em;
}

.main-container {
  display: flex;
}

.title {
  font-size: 3rem;
  display: grid;
  place-items: center;
}

/* Sidebar */
.sidebar {
  background: rgb(255, 255, 255);
  color: rgb(0, 0, 0);
  height: 100vh;
  overflow-y: auto;
}

.top_section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 10px;
}

.logo {
  font-size: 18px;
  line-height: 0;
}

.bars {
  width: 30px;
}

.hide {
  display: none;
}

.search {
  display: flex;
  align-items: center;
  margin: 10px 0;
  height: 30px;
  padding: 10px;
}

.search input {
  border: none;
  margin-left: 10px;
  border-radius: 5px;
  background: rgb(238, 238, 238);

  color: white;
}

.routes {
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.link {
  display: flex;
  color: rgb(0, 0, 0);
  gap: 10px;
  padding: 5px 10px;
  border-right: 4px solid transparent;
  transition: 0.2s cubic-bezier(0.6, -0.28, 0.735, 0.045);
}

.link:hover {
  border-right: 4px solid white;
  background: rgb(255, 255, 255);
  transition: 0.2s cubic-bezier(0.6, -0.28, 0.735, 0.045);
}

.active {
  border-right: 4px solid white;
  background: rgb(255, 255, 255);
}

.link_text {
  white-space: nowrap;
  font-size: 15px;
}

.menu {
  display: flex;
  color: white;

  padding: 5px 10px;
  border-right: 4px solid transparent;
  transition: 0.2s cubic-bezier(0.6, -0.28, 0.735, 0.045);
  justify-content: space-between;
}

.menu_item {
  display: flex;
  gap: 10px;
}

.menu_container {
  display: flex;
  flex-direction: column;
}

.menu_container .link {
  padding-left: 20px;
  border-bottom: #fff 0.5px solid;
}

.create-user {
  margin: auto;
  width: 100vh;
}

.user-box {
  padding: 0px 2em;
  margin-left: 26%;
}

.navbar {
  background: rgb(255, 255, 255);
}

.edit-icon {
  float: right;
}

.user-info-heading,
.company-info-heading {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  text-align: center;
  line-height: 16px;
  font-size: 2rem;
}

div.scroll {
  height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
}

.delete_department {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.extra_field {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.overflow-auto-y {
  overflow-x: auto;
}

.document-upload {
  display: flex;
  flex-direction: column;
  float: right;
  align-items: flex-end;
}

.ant-upload-list-item,
.ant-upload-list-item-undefined {
  overflow: auto;
  white-space: nowrap;
}

.responsive-descriptions {
  display: flex;
  flex-direction: column;
}

.document-upload {
  flex-wrap: wrap;
}

/* Add media queries for responsiveness */
@media screen and (max-width: 768px) {
  .responsive-descriptions {
    /* Adjust layout for smaller screens, e.g., remove vertical layout */
    flex-direction: row;
  }

  .document-upload {
    /* Adjust alignment of document upload elements */
    justify-content: space-between;
  }
}

.scrollable-container {
  max-height: 800px;
  overflow-y: auto;
}

.fixed-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
}

@media (max-width: 768px) {
  .header-button-hamburger {
    display: block;
  }

  .header-button-normal {
    display: none;
  }
}

.ant-menu-item:hover,
.ant-menu-submenu-title:hover {
  background-color: #EBE8F7 !important;
  color: black !important;
}

.ant-menu-item-selected {
  background-color: #EBE8F7 !important;
  color: black !important;
}

.ant-menu-submenu-title {
  color: #EBE8F7 !important;
}


/* .ant-tabs-nav-list >  div{
  color: rgb(0, 0, 0);
  border: 2px solid red;
  border-radius: 20px;
} */

/* Use a more specific selector */
.ant-tabs.ant-tabs-card .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #4E36A3 !important;
  font-size: larger;
  /* font-weight: bolder; */
  text-shadow: 0 0 0.25px currentcolor;
}

.ant-tabs.ant-tabs-card .ant-tabs-tab.ant-tabs-tab-btn {
  color: #4E36A3 !important;
  font-size: larger;
  font-weight: bolder;
  text-shadow: 0 0 0.25px currentcolor;
}

.ant-tabs-card.ant-tabs-top>.ant-tabs-nav .ant-tabs-tab-active,
.ant-tabs-card.ant-tabs-top>div>.ant-tabs-nav .ant-tabs-tab-active {
  box-shadow: 0px -9px 0px -4px inset #4E36A3;
}

.ant-tabs-card.ant-tabs-top>div>.ant-tabs-nav .ant-tabs-tab-active {
  box-shadow: 0px -9px 0px -4px inset #4E36A3;
}

:where(.css-dev-only-do-not-override-w8mnev).ant-tabs .ant-tabs-tab:hover {
  color: #4E36A3;
}

.ant-table-tbody>tr>td {
  border-bottom: 1px solid red;
  /* Change 'red' to your desired color */
}

:where(.css-dev-only-do-not-override-w8mnev).ant-modal .ant-modal-title {
  font-size: 27px;
}

:where(.css-dev-only-do-not-override-w8mnev).ant-modal .ant-modal-footer {
  display: flex;
  flex-direction: inherit;
  align-items: center;
  justify-content: space-between;
}

.user-card {
  background-color: #F8F7FC;
  border-bottom: 2px solid #4E36A3;
}

:where(.css-dev-only-do-not-override-w8mnev).ant-descriptions .ant-descriptions-row>td {
  padding-right: 5em;
}

/* Add custom styling for the dropdown */
.custom-dropdown {
  .ant-select-item-option-content {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

:where(.css-dev-only-do-not-override-w8mnev).ant-modal .ant-modal-footer {
  margin-top: 55px;
}

.ant-col-md-4 {
  max-width: 100%;
}

.user-heading {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: space-between;
  justify-content: space-between;
  align-items: center;
}

.ant-btn-primary {
  background-color: #4E36A3;
}

.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.truncate:hover {
  white-space: normal;
  overflow: visible;
  text-overflow: initial;
}

.custom-input-number-container .ant-input-number-handler-wrap {
  opacity: 1;
  visibility: visible;
}

.ant-input-number .ant-input-number-handler-wrap {
  opacity: 1;
}

.ant-table-wrapper .ant-table-pagination {
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: space-around;
  justify-content: center;

}

/* styles.css */

.left-container {
  /* max-width: 800px; */
  margin: auto;
}

.left-heading {
  font-weight: bold;
  font-size: 1em;
  margin-bottom: 0.5em;
  text-align: justify;
}

.left-subheading {
  font-weight: bold;
  font-size: .9em;
  margin-bottom: 0.5em;
  text-align: justify;
}

.left-list-item {
  font-size: .6em;
  list-style-type: none;
  margin-left: 1em;
  position: relative;
  text-align: justify;
  margin-bottom: .2em;
}

.left-dot {
  font-weight: bold;
  font-size: .6em;
  position: absolute;
  left: -1em;
  top: 0.2em;
}

.left-bold-heading {
  font-weight: bold;
  font-size: 1.2em;
  color: #A3C5F1;
}

@media only screen and (max-width: 924px) {
  .left-heading {
    font-size: .8em;
  }

  .left-subheading {
    font-size: 1em;
  }
}

.tenure-input-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: .8em;
}

.ant-space-item:hover {
  color: black;
}

a> :hover {
  color: black
}

.ant-modal-root .ant-modal-wrap {
  top: -77px
}

.agreement-details {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;

}

.ant-upload-wrapper .ant-upload-select {
  width: 100%;
}
.menuButtton{
  border-color: transparent;
}
.menuButtton:hover{
  color: #4E36A3 !important;
  border-color: transparent !important;
}

@media (min-width: 1230px) {
  .anlytics-graph {
    flex-direction: row;
  }
}

.product-table-update{
  width: 100% !important;
}
.custom-table-update {
  width: 60% !important;
}
.attachment-modal{
  width: 75% !important;
}
.attachment-input{
margin-bottom: 0 !important;
}

.ant-descriptions.ant-descriptions-bordered >.ant-descriptions-view .ant-descriptions-row >.ant-descriptions-item-label {
  font-weight: bold;
}
.ant-descriptions .ant-descriptions-item-container .ant-descriptions-item-label {
  font-weight: bold !important;
}

/* .product-table > .ant-spin-nested-loading  > .ant-spin-container > .ant-table-bordered > .ant-table-container > .ant-table-content{
  overflow: visible !important;
}
.product-table > .ant-spin-nested-loading  > .ant-spin-container > .ant-table-bordered > .ant-table-container > .ant-table-content{

}
.ant-table-wrapper .ant-select-dropdown {
} */

.clause-modal{
  width: 80% !important;
}


.ant-menu-item{
  color: #CCEBFF !important;
}

.ant-menu-item-selected{
  color: rgb(0, 0, 0)!important;
}

/* Define the style for the placeholder */
.custom-placeholder::placeholder {
  color: rgba(0, 0, 0, 0.5); /* You can adjust the color */
  font-weight: bold; /* This does not directly work but keep it for font weight */
  opacity: 1; /* Ensures that the placeholder text is fully opaque */
}
