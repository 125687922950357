@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;;400;500;600;700&display=swap');


*{
  font-family: 'Inter', sans-serif;
  background-color: "#EBE8F7";
}

body {
  margin: 0;
  padding: 0;
  /* background-color: aqua; */
}