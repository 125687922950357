
.container {
    display: inline-block;
    position: relative;
    margin-right: 16px;
  }
  
  .button {
    width: 100px;
    height: 32px;
  }
  
  .monthsContainer {
    position: absolute;
    top: 32px;
    left: 0;
    width: 100px;
    background-color: white;
    border: 1px solid #d9d9d9;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  }
  
  .monthItem {
    padding: 4px 8px;
    text-align: center;
    cursor: pointer;
  }
  
  .monthItem:hover {
    background-color: #f5f5f5;
  }